@import-normalize; /* https://create-react-app.dev/docs/adding-css-reset */
@import url('https://fonts.googleapis.com/css2?family=Aleo&family=Lato&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;  /* https://developer.mozilla.org/en-US/docs/Web/CSS/box-sizing */
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
  font-family: "Aleo", "Times New Roman", Times, serif;
}

html {
  height: 100%;
  font-size: 14px;
}

body {
  height: 100%;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, source-sans-pro, sans-serif;
}